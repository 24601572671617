export const faq = [
    {
        id: 1,
        question: 'Bagaimana format untuk motivation letter',
        answer:
            ' motivation letter berisi alasan kalian mendaftar beasiswa  BI dan alasan mengapa kalian layak untuk diterima. Buat motivation letter dengan sejujur-jujurnya. Pastikan hasilnya rapih dan terstruktur. Motivation letter akan menjadi perkenalan pertama kalian dengan pewawancara.',
    },
    {
        id: 2,
        question: 'Surat rekomendasi bisa diberikan oleh siapa',
        answer:
            'Untuk rekomendasi dari tokoh akademik bisa diberikan oleh dosen pembimbing, dekan, kaprodi, dll. Untuk rekomendasi dari tokoh non-akademik bisa diberikan oleh ketua himpunan, ketua BEM, ketua organisasi/komunitas, manager di tempat magang, dll.',
    },
    {
        id: 3,
        question: 'Apakah ada format tertentu untuk surat rekomendasi',
        answer:
            'Tidak ada format untuk surat rekomendasi. Pastikan bahwa surat rekomendasinya resmi. Apabila menggunakan surat rekomendasi dari organisasi, pastikan bahwa kop suratnya sesuai.',
    },
    {
        id: 4,
        question:
            'Apabila saya sedang mendaftar beasiswa lain apakah boleh mendaftar beasiswa BI',
        answer:
            'Selama belum diterima, boleh. Apabila nanti ternyata kamu diterima di kedua beasiswa tersebut maka beasiswa BI akan dicabut. Penerima beasiswa BI sama sekali tidak boleh mendapatkan beasiswa lain.',
    },
    {
        id: 5,
        question:
            'Apakah sertifikat IELTS bisa digunakan untuk menggantikan TOEFL?',
        answer:
            'Bisa, sertifikat bahasa inggris selain TOEFL bisa digunakan asalkan nilainya tetap lebih dari TOEFL 500 ketika disetarakan. Pastikan bahwa organisasi yang memberikan sertifikat tersebut adalah organisasi yang resmi ya.        ',
    },
    {
        id: 6,
        question: 'Apakah sertifikat TOEFL Prediction Test bisa digunakan?',
        answer:
            'Bisa, kami menerima TOEFL Prediction Test asalkan nilainya tetap memenuhi syarat > 500.        ',
    },
    {
        id: 7,
        question: 'Apakah SKTM wajib dimiliki?',
        answer:
            'Kamu bisa mendaftar beasiswa BI tanpa memberikan SKTM. Namun demikian BI akan memprioritaskan pendaftar yang dapat menunjukkan SKTM.        ',
    },
    {
        id: 8,
        question: 'Berapa sertifikat yang boleh diupload di formulir?',
        answer:
            ' Tidak ada maksimal sertifikat yang boleh diupload. Namun demikian pastikan bahwa sertifikat yang kalian upload adalah yang masih relevan ya. Sertifikat yang umurnya sudah lama sekali (lebih dari 5 tahun misalnya) mungkin harus dipertanyakan relevansinya.',
    },
    {
        id: 9,
        question: 'Kalau tidak punya sertifikat yang mendukung bagaimana?',
        answer:
            'Sertifikat hanya menjadi nilai tambah saja. Ada banyak faktor lain yang mempengaruhi kemungkinan kalian diterima oleh BI. Kalau memang tidak punya sertifikat, pastikan bisa menang di wawancara. Jangan menyerah! Persiapkan segalanya dengan baik ya.',
    },
    {
        id: 10,
        question: 'Siapa yang akan menyeleksi calon beswan?',
        answer:
            'Yang akan menyeleksi calon beswan adalah dari pihak BI, bukan GenBI atau pihak lain. BI bisa saja meminta bantuan dari konsultan/psikolog pada saat wawancara. Namun hal ini tidak pasti, yang pasti adalah penyeleksinya dari pihak BI.',
    },
    {
        id: 11,
        question: 'Apakah ada tips untuk wawancara?',
        answer:
            'Persiapan. Siapkan diri kalian, siapkan jawaban untuk pertanyaan-pertanyaan umum yang diajukan saat wawancara, dan pelajari Bank Indonesia sebelum kalian wawancara. Tidur yang cukup, makan yang cukup supaya hasil wawancara maksimal.',
    },
    {
        id: 12,
        question: 'Apa saja perbedaan antara beasiswa reguler dan unggulan?',
        answer:
            'Perbedaannya hanya ada di syarat pendaftaran dan uang beasiswa. Seluruh penerima beasiswa BI dapat mengikuti kegiatan yang diselenggarakan BI.',
    },
    {
        id: 13,
        question: 'Bagaimana kalau saya ditolak oleh beasiswa BI?',
        answer:
            'Jangan menyerah! Ada banyak beasiswa lain yang juga keren selain beasiswa BI. Kamu bisa berkarya dimanapun kamu berada. Kalau kamu ditolak, jangan menyerah dan coba terus. Suatu saat kamu pasti akan berhasil',
    },
    {
        id: 14,
        question:
            'Apakah mahasiswa semester 8 masih bisa mendaftar Beasiswa BI?',
        answer:
            'Ya, mahasiswa semester 8 masih bisa mendaftar Beasiswa BI selama SKL belum turun dan sudah memenuhi semua persyaratan lainnya.',
    },
]
