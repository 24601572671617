<template>
    <div>
        <div class="tab__header border-black">
            <a
                href="#"
                class="tab__link mb-1 p-6 block text-black hover:bg-gray-100 transition   flex justify-between rounded"
                @click.prevent="active = !active"
                style="border:0.5px solid #dadce0"
            >
                <strong>{{ item.id }}. {{ item.question }} </strong>
                <span class="text-white" v-show="!active">&#10133;</span>
                <span class="text-white" v-show="active">&#10134;</span>
            </a>
        </div>
        <div
            class="tab__content px-10 py-6 block bg-blue-50 mb-2"
            v-show="active"
            style="text-align:start"
        >
            <slot />
            {{ item.answer }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: function() {
                return {}
            },
        },
    },
    data() {
        return {
            active: false,
            // item: {
            //     id: 1,
            //     name: 'test data one',
            //     text: 'this is some accordion text',
            // },
        }
    },
}
</script>

<style></style>
