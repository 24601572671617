<template>
    <div class="container mx-auto py-10 ">
        <h1 class="text-2xl font-bold">Frequently Asked Questions</h1>
        <section class="mt-10">
            <accordion
                class="mb-3"
                :item="accordion"
                v-for="accordion in item"
                :key="accordion.id"
            />
        </section>
    </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import { faq } from '@/data/faq.js'
export default {
    components: {
        Accordion,
    },
    mounted() {
        window.scrollTo(0, 0)
    },
    data() {
        return {
            // item: [
            //     {
            //         id: 1,
            //         name: 'name',
            //         text: 'text',
            //     },
            //     {
            //         id: 2,
            //         name: 'name',
            //         text: 'text',
            //     },
            // ],
            item: faq,
        }
    },
    metaInfo: {
        titleTemplate: `%s - FAQ`,
        htmlAttrs: {
            lang: 'id',
        },
    },
}
</script>

<style></style>
